<template>
    <div>
      <h1>Impressum:</h1>
      <p>CitizenLove wurde von Freiwilligen gebaut und wird unterstützt durch Campax.</p>
      <h2>Für Medienanfragen:</h2>
      <p>Manuel Frick, media@citizen.love</p>
      <h2>Für alle anderen Fragen:</h2>
      <p>contact@citizen.love</p>
    </div>
</template>

<script>
    export default {
        name: "Imprint.vue"
    }
</script>

<style scoped>

</style>