<template>
  <div>
    <h1 class="c16" id="h.mvoktp6upjen"><span class="c12">Datenschutzerklärung</span></h1>
    <p class="c23"><span class="c4">Diese Datenschutzerkl&auml;rung beschreibt, welche Massnahmen CitizenLove ergreift, um Ihre Privatsph&auml;re zu wahren. Wenn Sie unsere Website nutzen, vertrauen Sie uns Daten an. Besonders sch&uuml;tzenswert sind dabei von uns erhobene personenbezogene Daten. Im Folgenden erl&auml;utern wir Ihnen, wie und welche Daten von uns erfasst werden, warum wir das tun und was wir mit den erfassten Daten machen.</span>
    </p><h4 class="c10" id="h.1ozye82xls12"><span class="c6">Personenbezogene Daten</span></h4>
    <p class="c1"><span class="c4">Personenbezogen sind Daten dann, wenn sie mit Ihrer Person in Verbindung gebracht werden k&ouml;nnen und Aussagen zu Ihrem Verhalten oder Ihren Eigenschaften liefern. Dazu geh&ouml;ren Ihr Name, Adresse, Postanschrift und Telefonnummer, aber auch Angaben zu Ihren m&ouml;glichen Interessen oder Ihrem Spendenverhalten sowie Ihren Surfgewohnheiten, sofern diese Angaben Ihnen pers&ouml;nlich direkt oder indirekt zugeordnet werden k&ouml;nnen.</span>
    </p>
    <h2 class="c0" id="h.eo80tbnqva18"><span class="c13">Verantwortlich f&uuml;r die Datenverarbeitung</span></h2>
    <p class="c32"><span
      class="c4">Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO) ist:</span>
    </p>
    <p class="c1"><span class="c4">CitizenLove<br>E-Mail: contact@citizen.love</span></p>
    <h2 class="c0" id="h.prcpz2a12e02"><span class="c13">Kontaktm&ouml;glichkeit des Datenschutzbeauftragten</span></h2>
    <p class="c23"><span class="c4">Unsere Datenschutzbeauftragte erreichen Sie unter contact@citizen.love.</span></p>
    <h2 class="c0" id="h.ae0y3uo4vbjg"><span class="c13">Ihre Rechte</span></h2>
    <p class="c1"><span class="c4">Sie haben gegen&uuml;ber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:</span>
    </p>
    <h3 class="c10" id="h.ksemhjjwuoyn"><span class="c9">Allgemeine Rechte</span></h3>
    <p class="c1"><span class="c4">Sie haben ein Recht auf Auskunft, Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung, Widerspruch gegen die Verarbeitung und auf Daten&uuml;bertragbarkeit. Soweit eine Verarbeitung auf Ihrer Einwilligung beruht, haben Sie das Recht, diese uns gegen&uuml;ber mit Wirkung f&uuml;r die Zukunft zu widerrufen.</span>
    </p>
    <h3 class="c10" id="h.b9ont7sfucg7"><span
      class="c9">Rechte bei der Datenverarbeitung nach dem berechtigten Interesse</span></h3>
    <p class="c1"><span class="c4">Sie haben gem. Art. 21 Abs. 1 DSGVO das Recht, aus Gr&uuml;nden, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1e DSGVO (Datenverarbeitung im &ouml;ffentlichen Interesse) oder aufgrund Artikel 6 Abs. 1f DSGVO (Datenverarbeitung zur Wahrung eines berechtigten Interesses) erfolgt, Widerspruch einzulegen, dies gilt auch f&uuml;r ein auf diese Vorschrift gest&uuml;tztes Profiling. Im Falle Ihres Widerspruchs verarbeiten wir Ihre personenbezogenen Daten nicht mehr, es sei denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder die Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen.</span>
    </p>
    <h3 class="c10" id="h.dqgak699s68u"><span class="c9">Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde</span>
    </h3>
    <p class="c1"><span class="c4">Sie haben zudem das Recht, sich bei einer zust&auml;ndigen Datenschutz-Aufsichtsbeh&ouml;rde &uuml;ber die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.</span>
    </p>
    <h2 class="c0" id="h.fmknjowqeoj3"><span
      class="c13">Erhebung personenbezogener Daten bei Besuch unserer Website</span>
    </h2>
    <p class="c1"><span class="c4">Bei der bloss informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen &uuml;bermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server &uuml;bermittelt. Wenn Sie unsere Website betrachten m&ouml;chten, erheben wir die folgenden Daten, die f&uuml;r uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilit&auml;t und Sicherheit zu gew&auml;hrleisten. Rechtsgrundlage daf&uuml;r ist Art. 6 Abs. 1f DSGVO:</span>
    </p>
    <ul class="c15 lst-kix_k2xuzk2qo6u-0 start">
      <li class="c1 c21"><span class="c4">IP-Adresse: F&uuml;r den Abruf von Web-Seiten von CitizenLove ist die kurzfristige Registrierung der IP-Nummer unabdingbar. Die IP-Adresse ist die weltweit g&uuml;ltige, eindeutige Kennzeichnung eines Computers und besteht aus vier durch Punkte getrennten Zifferbl&ouml;cken. Zumeist werden Sie als private*r Nutzer*in keine gleichbleibende IP-Adresse benutzen, da Ihnen diese von Ihrem Provider nur vor&uuml;bergehend f&uuml;r eine Sitzung zugewiesen wird. Dennoch ist bei statischen IP-Adressen eine eindeutige Zuordnung der Nutzer*innendaten &uuml;ber dieses Merkmal im Prinzip m&ouml;glich. Unsere Webserver speichern IP-Adressen f&uuml;r maximal 24 Stunden. Danach werden die Zugriffsdaten anonymisiert.</span>
      </li>
      <li class="c1 c21"><span class="c4">Datum und Uhrzeit der Anfrage</span></li>
      <li class="c1 c21"><span class="c4">Zeitzonendifferenz zur Greenwich Mean Time (GMT)</span></li>
      <li class="c1 c21"><span class="c4">Inhalt der Anforderung (konkrete Seite)</span></li>
      <li class="c1 c21"><span class="c4">Zugriffsstatus/HTTP-Statuscode</span></li>
      <li class="c1 c21"><span class="c4">jeweils &uuml;bertragene Datenmenge</span></li>
      <li class="c1 c21"><span class="c4">Website, von der die Anforderung kommt</span></li>
      <li class="c1 c21"><span class="c4">Browser</span></li>
      <li class="c1 c21"><span class="c4">Betriebssystem und dessen Oberfl&auml;che</span></li>
      <li class="c1 c21"><span class="c4">Sprache und Version der Browsersoftware</span></li>
    </ul>
    <h2 class="c0" id="h.ldwytw4g2cvq"><span class="c13">Kontaktaufnahme per E-Mail oder Kontaktformular</span></h2>
    <p class="c1"><span class="c4">Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder &uuml;ber ein Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Soweit wir &uuml;ber unser Kontaktformular Eingaben abfragen, die nicht f&uuml;r eine Kontaktaufnahme erforderlich sind, haben wir diese als optional gekennzeichnet. Diese Angaben dienen uns zur Konkretisierung Ihrer Anfrage und zur verbesserten Abwicklung Ihres Anliegens. Eine Mitteilung dieser Angaben erfolgt ausdr&uuml;cklich auf freiwilliger Basis und mit Ihrer Einwilligung, Art. 6 Abs. 1a DSGVO. Soweit es sich hierbei um Angaben zu Kommunikationskan&auml;len (beispielsweise E-Mail-Adresse, Telefonnummer) handelt, willigen Sie ausserdem ein, dass wir Sie ggf. auch &uuml;ber diesen Kommunikationskanal kontaktieren, um Ihr Anliegen zu beantworten. Diese Einwilligung k&ouml;nnen Sie selbstverst&auml;ndlich jederzeit f&uuml;r die Zukunft widerrufen.</span>
    </p>
    <p class="c1"><span class="c4">Die in diesem Zusammenhang anfallenden Daten l&ouml;schen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schr&auml;nken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.</span>
    </p>
    <h2 class="c0" id="h.b6c5vsj2ynhd"><span class="c13">Einsatz von Cookies</span></h2>
    <p class="c1"><span class="c4">Bei Ihrer Nutzung unserer Website werden Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt, bestimmte Informationen zufliessen. Cookies k&ouml;nnen keine Programme ausf&uuml;hren oder Viren auf Ihren Computer &uuml;bertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.</span>
    </p>
    <p class="c1"><span class="c4">Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erl&auml;utert werden:</span>
    </p>
    <h3 class="c10" id="h.pfby8neij0wb"><span class="c9">Transiente Cookies</span></h3>
    <p class="c1"><span class="c4">Diese Cookies werden automatisiert gel&ouml;scht, wenn Sie den Browser schliessen. Dazu z&auml;hlen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zur&uuml;ckkehren. Die Session-Cookies werden gel&ouml;scht, wenn Sie den Browser schliessen.</span>
    </p>
    <h3 class="c10" id="h.pp9zw26yex42"><span class="c9">Persistente Cookies</span></h3>
    <p class="c1"><span class="c4">Diese Cookies werden automatisiert nach einer vorgegebenen Dauer gel&ouml;scht, die sich je nach Cookie unterscheiden kann. Sie k&ouml;nnen die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit l&ouml;schen.</span>
    </p>
    <h3 class="c10" id="h.cqjqzch4zvot"><span class="c9">Third-Party-Cookies</span></h3>
    <p class="c1"><span class="c4">Wir nutzen auf dieser Website sog. Third-Party-Cookies (Drittanbietercookies). Ein Third-Party-Cookie wird durch einen Dritten gesetzt, also nicht durch die eigentliche Website, auf der Sie sich gerade befinden. Third Party Cookies k&ouml;nnen die Anzahl der Seitenaufrufe sowie die Verweildauer auf einer Website oder den Weg, den ein Nutzer via Hyperlinks zur&uuml;ckgelegt hat, speichern. Sie erm&ouml;glichen das Tracking von Nutzer*innenverhalten &uuml;ber verschiedene Websites hinweg. Sie k&ouml;nnen Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit l&ouml;schen. </span>
    </p>
    <h3 class="c10" id="h.ihuhg6ffq9ct"><span class="c9">Verhinderung von Cookies</span></h3>
    <p class="c1"><span class="c4">Sie k&ouml;nnen Ihre Browser-Einstellung entsprechend Ihren W&uuml;nschen konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie dann eventuell nicht alle Funktionen dieser Website nutzen k&ouml;nnen.</span>
    </p>
    <h3 class="c10" id="h.qf4itnm3avyd"><span class="c9">Rechtsgrundlagen und Speicherdauer</span></h3>
    <p class="c1"><span class="c4">Die Rechtsgrundlagen f&uuml;r m&ouml;gliche Verarbeitungen personenbezogener Daten und deren Speicherdauer variieren und werden in den folgenden Abschnitten dargestellt.</span>
    </p>
    <h2 class="c0" id="h.8b4bs78xho35"><span class="c13">Webseitenanalyse</span></h2>
    <p class="c1"><span class="c4">F&uuml;r Zwecke der Analyse und Optimierung unserer Webseiten verwenden wir verschiedene Dienste, die im Folgenden dargestellt werden. So k&ouml;nnen wir z.B. analysieren, wie viele Nutzer*innen unsere Seite besuchen, welche Informationen am gefragtesten sind oder wie Nutzer*innen das Angebot auffinden. Wir erfassen unter anderem Daten dar&uuml;ber, von welcher Internetseite eine betroffene Person auf eine Internetseite gekommen ist (sogenannter Referrer), auf welche Unterseiten der Internetseite zugegriffen oder wie oft und f&uuml;r welche Verweildauer eine Unterseite betrachtet wurde. Dies hilft uns, unsere Website nutzerfreundlich auszugestalten und zu verbessern. Die dabei erhobenen Daten dienen nicht dazu, einzelne Benutzer*innen pers&ouml;nlich zu identifizieren. Es werden anonyme bzw. h&ouml;chstens pseudonyme Daten erhoben. Rechtsgrundlage daf&uuml;r ist Art. 6 Abs. 1 fDSGVO.</span>
    </p>
    <h3 class="c10" id="h.my0kpvkca3in"><span class="c9">Verwendung von Google Analytics</span></h3>
    <p class="c1"><span class="c4">Wir richten uns bei der Konfiguration von Google Analytics nach den Vorgaben des Hamburgischen Beauftragten f&uuml;r Datenschutz und Informationsfreiheit.</span>
    </p>
    <p class="c1"><span class="c4">Diese Website benutzt Google Analytics, einen Webanalysedienst der Google LLC, (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) Die Nutzung umfasst die Betriebsart Universal Analytics. Hierdurch ist es m&ouml;glich, Daten, Sitzungen und Interaktionen &uuml;ber mehrere Ger&auml;te hinweg einer pseudonymen User-ID zuzuordnen und so die Aktivit&auml;ten eine*r Nutzer*in ger&auml;te&uuml;bergreifend zu analysieren.</span>
    </p>
    <p class="c1"><span>Google Analytics verwendet Cookies, die eine Analyse der Benutzung der Website durch Sie erm&ouml;glichen. Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in den USA &uuml;bertragen und dort gek&uuml;rzt. Die im Rahmen von Google Analytics von Ihrem Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengef&uuml;hrt. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports &uuml;ber die Websiteaktivit&auml;ten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegen&uuml;ber dem Websitebetreiber zu erbringen. In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung. Die Rechtsgrundlage f&uuml;r den Einsatz von Google Analytics ist 1f DSGVO. Die von uns gesendeten und mit Cookies oder Nutzerkennungen (z. B. User-ID) verkn&uuml;pften Daten werden nach 38 Monaten automatisch gel&ouml;scht. Die L&ouml;schung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat. N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter</span><span><a
      class="c7"
      href="https://www.google.com/url?q=https://www.google.com/analytics/terms/de.html&amp;sa=D&amp;ust=1585270519194000">&nbsp;</a></span><span
      class="c17"><a class="c7"
                     href="https://www.google.com/url?q=https://www.google.com/analytics/terms/de.html&amp;sa=D&amp;ust=1585270519194000">https://www.google.com/analytics/terms/de.html</a></span><span>&nbsp;bzw. unter</span><span><a
      class="c7"
      href="https://www.google.com/url?q=https://policies.google.com/?hl%3Dde&amp;sa=D&amp;ust=1585270519194000">&nbsp;</a></span><span
      class="c17"><a class="c7"
                     href="https://www.google.com/url?q=https://policies.google.com/?hl%3Dde&amp;sa=D&amp;ust=1585270519195000">https://policies.google.com/?hl=de</a></span>
    </p>
    <p class="c1"><span>Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich werden nutzen k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das</span><span><a
      class="c7"
      href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout?hl%3Dde&amp;sa=D&amp;ust=1585270519195000">&nbsp;</a></span><span
      class="c17"><a class="c7"
                     href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout?hl%3Dde&amp;sa=D&amp;ust=1585270519196000">https://tools.google.com/dlpage/gaoptout?hl=de</a></span><span
      class="c4">&nbsp;herunterladen und installieren. Opt-Out-Cookies verhindern die zuk&uuml;nftige Erfassung Ihrer Daten beim Besuch dieser Website. Um die Erfassung durch Universal Analytics &uuml;ber verschiedene Ger&auml;te hinweg zu verhindern, m&uuml;ssen Sie das Opt-Out auf allen genutzten Systemen durchf&uuml;hren. Wenn Sie hier klicken, wird das Opt-Out-Cookie gesetzt: Google Analytics deaktivieren.</span>
    </p>
    <h3 class="c10" id="h.dhwdr522x7df"><span class="c9">Verwendung von Google Maps</span></h3>
    <p class="c1"><span>Wir verwenden Google Maps, einen Dienst der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; &bdquo;Google&ldquo;). Mittels Google Maps wird Kartenmaterial in unsere Website eingebunden, beispielsweise zur Darstellung unseres Standortes. Sofern Sie eine Webseite unseres Internetangebotes aufrufen, in der Google Maps eingebunden ist, wird eine Verbindung zu einem Server von Google aufgebaut und Ihre IP-Adresse &uuml;bertragen, um die Karte anzeigen zu k&ouml;nnen, und ggf. dort gespeichert. Je nach genutztem Endger&auml;t und zugelassenen App-Berechtigungen, k&ouml;nnen auch Ihr Standort und Nutzer*innendaten &uuml;bertragen werden. Der Serverstandort kann dabei in den USA liegen. Rechtsgrundlage f&uuml;r die Nutzung von Google Maps ist unser berechtigtes Interesse an der Optimierung unseres Online-Angebotes, Art. 6 Abs. 1f DSGVO. Mehr Informationen zum Umgang von Google mit Nutzerdaten und Cookies finden Sie in der Datenschutzerkl&auml;rung von Google:</span><span><a
      class="c7"
      href="https://www.google.com/url?q=https://www.google.de/intl/de/policies/privacy/&amp;sa=D&amp;ust=1585270519196000">&nbsp;</a></span><span
      class="c17"><a class="c7"
                     href="https://www.google.com/url?q=https://www.google.de/intl/de/policies/privacy/&amp;sa=D&amp;ust=1585270519197000">https://www.google.de/intl/de/policies/privacy/</a></span><span>. &Uuml;ber die </span><span
      class="c17"><a class="c7"
                     href="https://www.google.com/url?q=http://www.google.com/settings/ads?hl%3Dde&amp;sa=D&amp;ust=1585270519197000">Anzeigeneinstellung</a></span><span
      class="c4">&nbsp;k&ouml;nnen Sie Ihre Werbeeinstellungen bei Google verwalten oder personalisierte Werbung deaktivieren.</span>
    </p>
    <h2 class="c0" id="h.ovoirv581ps1"><span class="c13">Daten&uuml;bermittlung</span></h2>
    <p class="c1"><span class="c4">Eine &Uuml;bermittlung Ihrer Daten an Dritte findet ausser in den oben genannten F&auml;llen grunds&auml;tzlich nicht statt, es sei denn, wir sind gesetzlich dazu verpflichtet, die Datenweitergabe ist zur Durchf&uuml;hrung eines Vertragsverh&auml;ltnisses erforderlich oder Sie haben zuvor ausdr&uuml;cklich in die Weitergabe Ihrer Daten eingewilligt.</span>
    </p>
    <p class="c1"><span class="c4">Externe Dienstleister*innen und Partner*innenunternehmen wie z.B. Email-Vesand-Unternehmer*innen erhalten Ihre Daten nur, soweit dies zur technisches Erbringung unserer Dienste erforderlich ist. In diesen F&auml;llen beschr&auml;nkt sich der Umfang der &uuml;bermittelten Daten jedoch auf das erforderliche Minimum. Soweit unsere externen Dienstleister*innen mit Ihren personenbezogenen Daten in Ber&uuml;hrung kommen, stellen wir im Rahmen der Auftragsverarbeitung gem. Art. 28 DSGVO sicher, dass diese die Vorschriften der Datenschutzgesetze in gleicher Weise einhalten. Bitte beachten Sie auch die jeweiligen Datenschutzhinweise der Anbieter*innen. F&uuml;r die Inhalte fremder Dienste ist die jeweilige Anbieter*in verantwortlich, wobei wir im Rahmen der Zumutbarkeit eine &Uuml;berpr&uuml;fung der Dienste auf die Einhaltung der gesetzlichen Anforderungen durchf&uuml;hren.</span>
    </p>
    <p class="c1"><span class="c4">Wir legen Wert darauf, Ihre Daten innerhalb der EU / des EWR zu verarbeiten. Es kann allerdings vorkommen, dass wir Dienstleister*innen einsetzen, die ausserhalb der EU / des EWR Daten verarbeiten. In diesen F&auml;llen stellen wir sicher, dass vor der &Uuml;bermittlung Ihrer personenbezogenen Daten ein angemessenes Datenschutzniveau der Empf&auml;nger*in hergestellt wird. Damit ist gemeint, dass &uuml;ber EU-Standardvertr&auml;ge oder einem Angemessenheitsbeschluss, wie dem EU-Privacy Shield, ein Datenschutzniveau erreicht wird, das mit den Standards innerhalb der EU vergleichbar ist.</span>
    </p>
    <h2 class="c28" id="h.2cet9pxof12e"><span class="c13">Datensicherheit und Datenmanagement</span></h2>
    <p class="c1"><span class="c4">Soweit aus dem Zusammenhang einer Datenerhebung erkennbar ist, dass Daten nicht zwingend ben&ouml;tigt werden und deshalb auch nicht als Pflichtdaten gekennzeichnet sind, sind solche Angaben freiwillig.</span>
    </p>
    <p class="c1"><span class="c4">Wir versichern Ihnen, dass wir Ihre Daten weder im allgemeinen Adresshandel an andere Unternehmen noch an andere Organisationen verkaufen oder vermieten. Selbstverst&auml;ndlich haben Sie das Recht, den Zugang von Informationen an Sie jederzeit zu stoppen und der Verwendung Ihrer Kommunikationsdaten f&uuml;r diesen Zweck zu widersprechen. Schreiben Sie hierzu an contact@citizen.love.</span>
    </p>
    <p class="c1"><span class="c4">Um unsere Aufgaben zu erf&uuml;llen und die Sicherheit unserer IT-Infrastruktur zu gew&auml;hrleisten, m&uuml;ssen wir mit Dienstleister*innen zusammenarbeiten, die f&uuml;r diesen Zweck gegebenenfalls auch personenbezogene Daten erhalten. Diese Dienstleister*innen sind vertraglich durch CitizenLove gebunden und d&uuml;rfen personenbezogene Daten, die sie in diesem Zusammenhang erhalten, nur f&uuml;r den erlaubten Zweck verwenden (siehe oben, Ziffer 13).</span>
    </p>
    <p class="c1"><span class="c4">Wir haben umfangreiche technische und betriebliche Schutzvorkehrungen getroffen, um Ihre Daten vor zuf&auml;lligen oder vors&auml;tzlichen Manipulationen, Verlust, Zerst&ouml;rung oder dem Zugriff unberechtigter Personen zu sch&uuml;tzen. Unsere Sicherheitsverfahren werden regelm&auml;ssig &uuml;berpr&uuml;ft und dem technologischen Fortschritt angepasst.</span>
    </p>
    <h2 class="c28" id="h.p8ux8chm6u7"><span class="c13">So kontaktieren Sie CitizenLove bei Fragen</span></h2>
    <p class="c1"><span class="c4">F&uuml;r Fragen, Anregungen oder Kommentare zum Thema Datenschutz wenden Sie sich bitte per E-Mail an die Datenschutzbeauftragte von CitizenLove unter der Adresse contact@citizen.love.</span>
    </p>
    <p class="c1"><span class="c4">Die rasante Entwicklung im Internet und in der technischen Entwicklung macht von Zeit zu Zeit Anpassungen und damit auch Erg&auml;nzungen unserer Datenschutzerkl&auml;rung erforderlich. Sie werden an dieser Stelle &uuml;ber die Neuerungen informiert.</span>
    </p>
    <p class="c1"><span class="c30">Stand M&auml;rz 2020</span></p>
  </div>
</template>

<script>
  export default {
    name: "PrivacyPolicy"
  }
</script>

<style scoped>

</style>